
window.toggl = function () {
    const mobileNav = document.getElementById("mobile-nav");
    if (mobileNav.classList.contains("hidden")) {
        mobileNav.classList.remove("hidden");
        mobileNav.classList.add("flex");
    } else {
        mobileNav.classList.add("hidden");
        mobileNav.classList.remove("flex");
    }
};